import React from 'react'
import Seo from '../components/seo'
import Container from '../components/container'
import Layout from '../containers/layout'

const NotFoundPage = () => (
  <Layout>
    <Seo title='404: Not found' />
    <Container>
      <h1>Not found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
